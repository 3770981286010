/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Tiles } from "@rebass/layout"
import { Flex, Box, Card, Heading, Text } from "rebass"
import { Footer } from "../components/footer"
import { Header } from "../components/header"
import { Container } from "../components/container"
import data from "../data/services"
import rings from "../images/rings.png"

const { title, description, services } = data

const ServiceCard = styled(Card)`
  padding-top: 45px;
  position: relative;
  ::before {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    content: "";
    background-color: red;
    top: -35px;
    left: calc(50% - 40px);
    background-color: ${({ evenIndex }) => (evenIndex ? "#005864" : "#A0BCC4")};
    background-image: url(${rings});
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
  }
`

const Ul = styled.ul``

export default props => (
  <React.Fragment>
    <Helmet>
      <title>Melanie Couper Physiotherapy | Services</title>
      <meta
        name="Description"
        content="Melanie Couper provides Musculosketal Physiotherapy, Pelvic Health Physiotherapy, and Ageing Ably services in Dorrigo & the Bellingen Shire"
      />
    </Helmet>
    <Header />
    <Container>
      <Flex
        flexDirection={["column", "column", "row"]}
        justifyContent="space-between"
        mb={5}
      >
        <Box width={["100%", "100%", "50%"]} mb={[4, 4, 0]}>
          <Heading
            color="darkGrey"
            fontFamily="body"
            mb={4}
            fontSize={5}
            fontWeight={300}
          >
            {title}
          </Heading>
          <Text fontFamily="body" color="textGrey" fontSize={1}>
            {description}
          </Text>
        </Box>
        <Box width={["100%", "100%", "40%"]}>
          <Img
            alt="massage bed"
            fluid={props.data.serviceImage.childImageSharp.fluid}
          />
        </Box>
      </Flex>
      <Tiles columns={[1, 3, 3]} mt={[0, 0, 6]} mb={[0, 0, 4]}>
        {services.map(({ key, title, info, items }, index) => (
          <ServiceCard
            key={key}
            evenIndex={index % 2 === 0}
            variant="serviceCard"
            maxWidth={280}
            mx={["auto", 0, 0]}
            mb={[4, 4, 0]}
          >
            <Box height="70px" mb={3} mt={2}>
              <Heading
                fontSize={3}
                textAlign="center"
                color="textGrey"
                fontFamily="body"
              >
                {title}
              </Heading>
            </Box>
            <Text
              fontWeight="bold"
              color="darkTextGrey"
              fontFamily="body"
              mb={2}
              fontSize={1}
            >
              {info}
            </Text>
            <Ul>
              {items.map(({ key, description }, index) => (
                <li
                  sx={{
                    color: "darkTextGrey",
                    fontFamily: "body",
                    fontSize: 1,
                  }}
                  key={key}
                >
                  {description}
                </li>
              ))}
            </Ul>
          </ServiceCard>
        ))}
      </Tiles>
    </Container>
    <Footer />
  </React.Fragment>
)

export const pageQuery = graphql`
  query {
    serviceImage: file(relativePath: { eq: "bed.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
