export default {
  title: "Services",
  description: `You do not need a doctor’s referral to visit Mel. Private health fund claims can be made at the time of treatment. If your complaint is complicated or chronic (I.e. has lasted more than 6 months) you can apply to your GP to see if you qualify for a patient care plan under Medicare to assist with the cost of treatment.`,
  services: [
    {
      key: "services-musc",
      title: "Musculoskeletal Physiotherapy",
      info:
        "General hands-on physiotherapy with associated electro-therapeutic modalities.",
      items: [
        {
          key: "services-musc-1",
          description: "Physiotherapy for the spine: Back pain, neck pain",
        },
        {
          key: "services-musc-3",
          description: "Sport injury physiotherapy",
        },
        {
          key: "services-musc-6",
          description: "Soft tissue & skeletal pain",
        },
        {
          key: "services-musc-7",
          description: "Work & insurance related Injuries (SIRA-registered)",
        },
        {
          key: "services-musc-8",
          description:
            "Pre-operative preparation & post-operative rehabilitation",
        },
        {
          key: "services-musc-9",
          description: "Movement assessment",
        },
        {
          key: "services-musc-10",
          description: "Postural assessment & strategy",
        },
        {
          key: "services-musc-11",
          description: "Massage",
        },
        { key: "service-musc-12", description: "General physiotherapy" },
      ],
    },
    {
      key: "service-pelvic-health",
      title: "Pelvic Health Physiotherapy",
      info:
        "Individualised pelvic health physiotherapy for women, men and to a lesser extent children. Experienced in providing pelvic physiotherapy to the LGBTQI community.",
      items: [
        {
          key: "service-pelvic-1",
          description: "Women’s & Men’s Health Physiotherapy",
        },
        {
          key: "service-pelvic-2",
          description:
            "Urinary & fecal continence issues: leaking, urgency, frequency & some forms of constipation",
        },
        {
          key: "service-pelvic-3",
          description: "Pelvic pain",
        },
        { key: "service-pelvic-4", description: "Sexual function" },
        {
          key: "service-pelvic-5",
          description: "Birth preparation & recovery",
        },
        {
          key: "service-pelvic-6",
          description: "Lactating breast care & mastitis management",
        },
        {
          key: "service-pelvic-7",
          description:
            "Pelvic Organ prolapse assessment, prevention & management",
        },
        {
          key: "service-pelvic-8",
          description:
            "Second Half of Life pelvic assessment & preventative planning",
        },
      ],
    },
    {
      key: "services-ably",
      title: "Ageing Ably",
      info:
        "Physiotherapy to strengthen and optimise ability from the middle-age onward.",
      items: [
        {
          key: "services-ably-1",
          description: "Physiotherapy for middle-age onward",
        },
        {
          key: "services-ably-2",
          description: "Physiotherapy for elderly",
        },
        {
          key: "services-ably-3",
          description: "Strategies for arthritis management",
        },
        {
          key: "services-ably-4",
          description: "Movement assessment & strategies",
        },
      ],
    },
  ],
}
